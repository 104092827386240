import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact" data-aos="fade-down">
      <strong>What's Next</strong>
      <h3>Let's Work Together!</h3>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque quae eum velit. Iste neque suscipit nostrum, minus inventore earum beatae iusto mollitia repellat nobis delectus obcaecati sapiente hic quam quos!</p>
      <a href="mailto:battuosai03@gmail.com" className='btn-link contact-btn'>
        Write Me An Email <i className="fa-solid fa-envelope"></i>
      </a>
    </section>
  )
}

export default Contact